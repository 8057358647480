@tailwind base;
@tailwind components;

@font-face {
  font-family: "Quicksand";
  src: url(./assets/fonts/Quicksand-Light.ttf) format("truetype");
  font-weight: 300;
}
@font-face {
  font-family: "Quicksand";
  src: url(./assets/fonts/Quicksand-Regular.ttf) format("truetype");
  font-weight: 400;
}
@font-face {
  font-family: "Quicksand";
  src: url(./assets/fonts/Quicksand-Medium.ttf) format("truetype");
  font-weight: 500;
}
@font-face {
  font-family: "Quicksand";
  src: url(./assets/fonts/Quicksand-SemiBold.ttf) format("truetype");
  font-weight: 600;
}
@font-face {
  font-family: "Quicksand";
  src: url(./assets/fonts/Quicksand-Bold.ttf) format("truetype");
  font-weight: 700;
}

@tailwind utilities;

/* Viewport height for mobile devices */
@supports (-webkit-touch-callout: none) {
  .h-screen {
    height: -webkit-fill-available;
  }
}

@layer components {
  body {
    position: fixed;
    width: 100vw;
  }

  /* Custom Select arrow */
  .select-wrapper {
    -webkit-appearance: none;
    appearance: none;
    -moz-appearance: none;
    background-image: url('./assets/img/down-arrow.svg');
    background-repeat: no-repeat;
    background-size: 14px 14px;
    background-position: calc(100% - 10px);
  }
  .select-wrapper-dark {
    -webkit-appearance: none;
    appearance: none;
    -moz-appearance: none;
    background-image: url('./assets/img/down-arrow-white.svg');
    background-repeat: no-repeat;
    background-size: 14px 14px;
    background-position: calc(100% - 10px);
  }

  .dark-icons::-webkit-calendar-picker-indicator,
  .dark-icons::-webkit-hour-picker-indicator  {
    filter: invert(1);
  }

  /* Custom loading wheel */
  .loading-box {
    width: 100vw;
    height: 100vh;
    position: fixed;
    z-index: 1000;
    background-color: rgba(255, 255, 255, 0.6);
  }
  .lds-ring {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
    margin-left: calc(50vw - 40px);
    margin-top: calc(50vh - 40px);
  }
  .lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 8px solid #00C1D4;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #00C1D4 transparent transparent transparent;
  }
  .lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
  }
  .lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
  }
  .lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
  }
  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}